import http from './api'

// https://sarkain.visualstudio.com/Tradesoft-server/_wiki/wikis/API%20documentation/1161/Get-emails
export const httpGetSellerEmails = (params) => {
  return http({
    url: `/companies/get_emails/` ,
    method: 'GET',
    params,
  })
}
