import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  FormGroup
} from "reactstrap";

import { Typography } from "stories/components";

const CustomClearText = () => 'clear';

const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'black' : 'black'
});

export default function SelectMulti({className, errorText, defaultValues, focused, label, options, required, disabled=false, values, setFieldValue, placeholder, ...rest}) {
  const { t } = useTranslation();
  const handleSelectChange = ({target}, child) => {
    
    if (child?.action === "select-option") {
      setFieldValue(child.name, [
        ...values[child.name],
        {id:child.option.value, name:child.option.label}
      ])
    }
    else if (child?.action === "remove-value") {
      console.log("SelectMulti remove-value child", child)
      console.log("SelectMulti remove-value values", values)
      
      let newValues = [
        ...values[child.name]
      ]
      console.log("SelectMulti remove-value newValues1", newValues)
      const index = newValues.findIndex(obj => obj.id == child?.removedValue?.value)
      newValues.splice(index, 1)
      console.log("SelectMulti remove-value newValues2", newValues)
      setFieldValue(child.name, newValues)
    }
    else if (child?.action === "clear") {
      setFieldValue(child.name, [])
    }
  }

  return (
  <>
    <FormGroup
        className={`mb-0 focused ? focused : ""`}
      >
        {label && (
          <Typography bold className={`labelInline ? "d-inline" : ""`}>{label} {required ? "*" : ""}</Typography>
        )}
        
        <Select
          {...rest}
          key={JSON.stringify(defaultValues)}
          className={`sk_select_multi_select ${className}`}
          closeMenuOnSelect={false}
          defaultValue={defaultValues}
          isMulti
          options={options}
          noOptionsMessage={() => function(){ return t('select_multi_no_options','Ei valintoja')}}
          onChange={handleSelectChange}
          isDisabled={disabled}
          placeholder={placeholder ? placeholder : t('select_multi_placeholder','Ei rajausta')}
          menuPortalTarget={document.body} // Render menu in the body
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }) // High z-index for visibility
          }}
        />
        
        { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
        )}
    </FormGroup>
  
  </>
  );
}

SelectMulti.defaultProps = {
  menuPlacement: "auto"
}

// i18next-extract-disable

/*
export const exampleOptions = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];
*/

/* EXAMPLE - Single select

const categories = [
    {id: 1, name: "Valinta A"},
    {id: 2, name: "Valinta B"},
    {id: 3, name: "Valinta C"}
]

const [category, setCategory] = useState()

const setFieldValue = (name, obj) => {
    setCategory(obj?.id)
}

const getOptions = useCallback(() => {
    return categories && categories.map(category => {
      return {value: category.id, label: category.name}
    })
}, [categories])
  
<SelectMulti
    closeMenuOnSelect={true}
    defaultValues={[category]}
    isMulti={false}
    label={t('Category')} 
    name="category"
    options={getOptions()}
    setFieldValue={setFieldValue}
    value={category}
/>

*/

/* EXAMPLE isMulti={true}

Same but make function for defaultValues

const getSelectedOptions = useCallback(() => {
  if (isMulti === false) {
    return [value];
  }
  return value && value.map(category => {
    return {value: category.id, label: category.name}
  })
}, [value])

<SelectMulti
  ...
  defaultValues={getSelectedOptions()}
/>

*/
// i18next-extract-enable