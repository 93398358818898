import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { generatePath } from "react-router";
import {
  Typography,
  Link,
  IconButton,
  Badge,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Pad,
} from "stories/components";
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetPurchaseOrders, httpGetPurchaseOrdersStatuses } from "services/purchase_orders"
import { getDate, getDateAndTime } from "services/datetime"
import PurchaseOrderPaymentEdit from "views/dashboard/PurchaseOrderPaymentEdit/PurchaseOrderPaymentEdit"

import SupplierOrderShippingEdit from "views/dashboard/SupplierOrderShippingEdit/SupplierOrderShippingEdit"

const PriceListOrders = ({match, history}) => {
  
  const { enums, getTabNameByKey, getTabIdByKey } = useAppContext()
  const { myUser } = useAuthContext()
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("draft")
  
  // Table
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState({})
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-id",
  })
  
  const getPurchaseOrdersStatuses = () => {
    const params = {
      buyer: match?.params?.order_type === "purchases",
      seller: match?.params?.order_type === "sales"
    }
    httpGetPurchaseOrdersStatuses(params).then(res => {
      setStatuses(res?.data)
    })
  }
  
  const getPurchaseOrders = useCallback(() => {
    getPurchaseOrdersStatuses()
    
    let params = {
      ...filters,
      buyer: match?.params?.order_type === "purchases",
      seller: match?.params?.order_type === "sales"
    }
    if (params.hasOwnProperty("state__in") === true) {
      params.state = undefined
    }
    
    setLoading(true)
    httpGetPurchaseOrders(params).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }, [filters])
  
  useEffect(() => {
    const tab = match?.params?.tab;
    if (tab) {
      setActiveTab(tab)
    }
  }, [match, getTabIdByKey])

  useEffect(() => {
    if (activeTab) {
      setFilters(s => ({
        ...s,
        state: getTabIdByKey(activeTab),
      }))
    }
  }, [activeTab, getTabIdByKey])

  
  useEffect(() => {
    if (filters?.state) {
      getPurchaseOrders();
    }
  }, [filters, getPurchaseOrders])
  
  const getTabName = useCallback(() => {
    if (activeTab === "sent") {
      return myUser?.is_customer ? t("price_list_orders_tab_sent","Lähetetty") : t("price_list_orders_tab_sent","Saapuneet")
    }
    return getTabNameByKey(activeTab)
  }, [activeTab, getTabNameByKey])
  
  const getPurchaseOrderStateName = useCallback((stateKey) => {
    const value = enums && enums?.purchase_orders?.purchase_order?.state.filter((item) => {
        return item.key === stateKey
      })[0].value
    return t(value) /* i18next-extract-disable-line */
  }, [enums, t])
  
  const handleEdit = useCallback((id) => {
    history.push(`/dashboard/price_list_orders/order/${id}/`);
  }, [history])
  
  const handleReview = useCallback((id) => {
    history.push(`/dashboard/price_list_orders/order/${id}/`);
  }, [history])
  
  const headers = [
    { label: t("price_list_orders_id","ID"), key: "id"},
    { label: t("price_list_orders_actions","Toimenpiteet"), key: "action_badges"},
    { label: t("price_list_orders_order_date_state_sent","Tilaus pvm"), key: "state_sent"},
    { label: t("price_list_orders_customer","Asiakas"), key: "buyer_email", visible: match?.params?.order_type==="sales"},
    { label: t("price_list_orders_seller_email","Myyjä"), key: "seller_email", visible: match?.params?.order_type==="purchases"},
    // { label: t("price_list_orders_customer_group","Asiakasryhmä"), key: "customer"},
    { label: t("price_list_orders_products_count","Päätuotteet"), key: "products_count"},
    { label: t("price_list_orders_product_variations_count","Variaatiot"), key: "product_variations_count"},
    // { label: t("price_list_orders_pre_orders","Sis.ennakkotil.tuotteet"), key: "pre_orders"},
    { label: t("price_list_orders_sum_vat0","Summa (ALV 0%)"), key: "total_order_value_without_vat"},
    { label: t("price_list_orders_sum_vat","Summa (sis. ALV)"), key: "total_order_value"},
    { label: t("price_list_orders_events","Tapahtumat"), key: "events"},
  ];
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {

      const actionBadgeValue = row?.purchase_order_product_rows_count - row?.products_confirmed_by_orderer_count + row?.products_comments_orderer_unread_count;

      return {
        ...row,
        id: row?.id,
        state_sent: (
          <Typography>{getDate(row?.state_sent)}</Typography>
        ),
        buyer_email: (
          <Typography>{row?.customer} {row?.buyer_email}</Typography>
        ),
        seller_email: (
          <Typography>{row?.seller_email}</Typography>
        ),
        products_count: (
          <Typography>{row?.products_count}</Typography>
        ),
        product_variations_count: (
          <Typography>{row?.product_variations_count}</Typography>
        ),
        total_order_value: (
          <Typography>{row?.total_order_value}</Typography>
        ),
        total_order_value_without_vat: (
          <Typography>{row?.total_order_value_without_vat}</Typography>
        ),
        events: (
          <>
            <div><Typography bold className="d-inline-block">{t("Tila")}:</Typography> <Typography className="d-inline-block">{getPurchaseOrderStateName(row?.state)} {row?.state_updated && getDateAndTime(row?.state_updated)}</Typography></div>
            <Link isExternal={true} id={`popover${row?.id}`}>{t("näytä tapahtumat")}</Link>
            <Popover
                placement="top"
                target={`popover${row?.id}`}
            >
              <div><Typography bold className="d-inline-block">{t("Lähetetty")}:</Typography> <Typography className="d-inline-block">{row?.state_sent ? getDateAndTime(row?.state_sent) : (<Badge color="danger">{t("Luonnos")}</Badge>)}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Tavarantoimittaja lukenut")}:</Typography> <Typography className="d-inline-block">{row?.read_last_supplier ? getDateAndTime(row?.read_last_supplier) : (<Badge color="danger">{t("Ei avattu")}</Badge>)}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Tavarantoimittaja käsitellyt")}:</Typography> <Typography className="d-inline-block">{row?.confirmed_by_supplier_dt ? getDateAndTime(row?.confirmed_by_supplier_dt) : <Badge color="danger">{t("Odottaa hyväksyntää")}</Badge>}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Vahvistettu")}:</Typography> <Typography className="d-inline-block">{row?.state_delivery ? getDateAndTime(row?.state_delivery) : "-"}</Typography></div>
              { row?.state > 1 && (
                <div><Typography bold className="d-inline-block">{t("Maksettu")}:</Typography> <Typography className="d-inline-block">{row?.payment_paid_state === 3 ? getDate(row?.payment_paid_state_dt) : row?.payment_paid_state === 2 ? (<Badge color="warning">{t("Maksettu osittain")}</Badge>) : (<Badge color="danger">{t("Odottaa maksua")}</Badge>)}</Typography></div>
              )}
              { row?.state > 1 && (
                <div><Typography bold className="d-inline-block">{t("Toimitettu")}:</Typography> <Typography className="d-inline-block"><Badge color="danger">{t("Odottaa, että tavarantoimittaja lähettää tuotteet")}</Badge></Typography></div>
              )}
              { row?.state >= 5 && (
                <div>
                  <Typography bold className="d-inline-block">{t("Toimitettu")}:</Typography> 
                  <Typography className="d-inline-block">&nbsp;{row?.products_delivered_count}/{row?.products_count}</Typography>
                  { row?.products_delivered_count < row?.products_count && (
                    <>
                      &nbsp;<Typography className="d-inline-block"><Badge color="danger">{t("Odottaa tuotteita")}</Badge></Typography>
                    </>
                  )}
                </div>
              )}
            </Popover>
          </>
        ),
        action_badges: (
          <>
            { row.state === 1 && (
              <>
                <Pad 
                  onClick={() => handleEdit(row?.id)}
                  icon="fa-exclamation-circle" 
                  color="warning"
                >
                  {t("Luonnos")}
                </Pad>
                <br />
              </>
            )}
            
            { row.state > 1 && (
            <>
              { !row?.confirmed_by_orderer || actionBadgeValue > 0 ? (
                <>
                  <Pad 
                    onClick={() => handleEdit(row?.id)}
                    icon="fa-exclamation-circle" 
                    color="danger" 
                    badgeColor="danger" 
                    badgeValue={actionBadgeValue}
                  >
                    {t("Käsittele tilaus")}
                  </Pad>
                  <br />
                </>
              ) : (
                <>
                { !row?.confirmed_by_supplier && (
                  <>
                    <Pad 
                      onClick={() => handleEdit(row?.id)}
                      icon="fa-exclamation-circle"
                      color="warning"
                    >
                      {t("Odottaa")}
                      <br />
                      {t("hyväksyntää")}
                    </Pad>
                    <br />
                  </>
                )}
                </>
              )}
              
              { row.state > 2 && (
              <>
                { row?.confirmed_by_orderer && row?.confirmed_by_supplier && (
                  <>
                    <Pad
                      onClick={() => handleEdit(row?.id)}
                      icon="fas fa-check"
                      color="success"
                    >
                      {t("Vahvistettu")}
                    </Pad>
  
                  </>
                )}
              </>
              )}
              
            </>
            )}
          </>
          ),
      }
      
    })
  }, [rows, getPurchaseOrderStateName, handleEdit, handleReview, t])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  // Modal: Payment
  
  const [modalOrderPaymentOpen, setModalOrderPaymentOpen] = useState(false)
  const [modalOrderPaymentId, setModalOrderPaymentId] = useState()
  
  const toggleModalOrderPayment = () => {
    setModalOrderPaymentOpen(s => !s);
  }
  
  const handleOpenModalPayment = (id) => {
    setModalOrderPaymentOpen(true)
    setModalOrderPaymentId(id);
  }
  
  const handleOrderPaymentResolve = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
    getPurchaseOrders();
  }
  
  const handleOrderPaymentReject = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
  }
  
  // Modal: Shipping
  
  const [modalOrderShippingOpen, setModalOrderShippingOpen] = useState(false)
  const [modalOrderShippingId, setModalOrderShippingId] = useState()
  
  const toggleModalOrderShipping = () => {
    setModalOrderShippingOpen(s => !s);
  }
  
  const handleOpenModalShipping = (id) => {
    setModalOrderShippingOpen(true)
    setModalOrderShippingId(id);
  }
  
  const handleOrderShippingResolve = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
    getPurchaseOrders();
  }
  
  const handleOrderShippingReject = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
  }
  
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          { match?.params?.order_type === "purchases" ? t("Ostotilaukset") : t("Myyntitilaukset") }
        </BreadcrumbItem>
        <BreadcrumbItem>
          { match?.params?.order_type === "purchases" ? t("Ostohistoria") : t("Myyntihistoria") }
        </BreadcrumbItem>
        <BreadcrumbItem>
          {getTabName()}
        </BreadcrumbItem>
      </Breadcrumb>
      
      <PageHeader title={ match?.params?.order_type === "purchases" ? t("Ostotilausten hallinta") : t("Myyntitilausten hallinta") }></PageHeader>
      
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === "draft" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              order_type: match?.params?.order_type,
              tab: "draft"
            })}
          ><i className="fas fa-exclamation-circle"></i> {t("price_list_orders_tab_draft","Luonnokset")} ({statuses?.status_counts?.state_1})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === "sent" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              order_type: match?.params?.order_type,
              tab: "sent"
            })}
          ><i className="fas fa-briefcase"></i> {myUser?.is_customer ? t("price_list_orders_tab_sent","Lähetetty") : t("price_list_orders_tab_sent","Saapuneet")} ({statuses?.status_counts?.state_2})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === "inprogress" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              order_type: match?.params?.order_type,
              tab: "inprogress"
            })}
          ><i className="far fa-calendar-check"></i> {t("price_list_orders_tab_inprogress","Käsittelyssä")} ({statuses?.status_counts?.state_3})</Link>
        </li>
        {/*
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === "ondelivery" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              order_type: match?.params?.order_type,
              tab: "ondelivery"
            })}
          ><i className="fas fa-truck"></i> {t("Saapuneet / matkalla")} ({statuses?.status_counts?.state_4})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${activeTab === "completed" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              order_type: match?.params?.order_type,
              tab: "completed"
            })}
          ><i className="fas fa-shopping-basket"></i> {t("Valmiit")} ({statuses?.status_counts?.state_5})</Link>
        </li>
        */}
      </ul>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
      <Modal
        title={t("Maksutiedot")}
        isOpen={modalOrderPaymentOpen}
        toggleModal={toggleModalOrderPayment}
      >
        <PurchaseOrderPaymentEdit
          purchaseOrderId={modalOrderPaymentId}
          handleResolve={handleOrderPaymentResolve}
          handleReject={handleOrderPaymentReject}
        />
      </Modal>
      
      <Modal
        title={t("Kuljetustiedot")}
        isOpen={modalOrderShippingOpen}
        toggleModal={toggleModalOrderShipping}
      >
        <SupplierOrderShippingEdit
          purchaseOrderId={modalOrderShippingId}
          handleResolve={handleOrderShippingResolve}
          handleReject={handleOrderShippingReject}
        />
      </Modal>
      
    </>
  );
}

export default PriceListOrders;
